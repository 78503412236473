<template>
  <a-modal
    title="新建商品折扣"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="活动名称" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-input v-decorator="['name', {rules: [{ required: true, message: '请输入' }]}]" placeholder="请输入折扣名称"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="商品名称">
              <a-select
                showSearch
                v-decorator="['barcode', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                placeholder="请输入商品名称"
                style="width: 100%"
                :filter-option="false"
                :not-found-content="goods_fetching ? undefined : null"
                allow-clear
                @search="this.goodsSearch"
                @change="handleGoodsChange"
              >
                <a-spin v-if="goods_fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in goodsList" :key="d.barcode" :value="d.barcode">
                  {{ d.name }}（{{ d.barcode }}）
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="false">
            <a-form-item label="商品数量" >
              <a-input-number style="width: 150px" :precision="2" :min="0" v-decorator="['count', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="开始时间">
              <a-date-picker
                v-decorator="['start_date', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                show-time
                :disabled-date="disabledStartDate"
                format="YYYY-MM-DD"
                placeholder="开始时间"
                :open="startOpen"
                @openChange="handleStartOpenChange"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="结束时间">
              <a-date-picker
                v-decorator="['end_date', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                show-time
                :disabled-date="disabledEndDate"
                format="YYYY-MM-DD"
                placeholder="结束时间"
                :open="endOpen"
                @openChange="handleEndOpenChange"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="折扣价格" >
              <a-input-number style="width: 150px" :precision="2" :min="0" v-decorator="['price', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'
import debounce from 'lodash/debounce'
import { listGoods } from '@/api/applet_goods'

const fields = ['warehouse_code', 'phone', 'name', 'province', 'city', 'county', 'address', 'longitude', 'latitude', 'is_used']
export default {
  name: 'DeliverAddressForm',
  components: {
  },
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        }
      },
      goodsList: [],
      selectGoods: undefined,
      goods_fetching: false,
      endOpen: false,
      startOpen: false,
      warehouse_fetching: false,
      dataSource_wms_warehouse: [],
      form: this.$form.createForm(this)
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.model !== null) {
        this.loadWarehouse({ code: this.model.warehouse_code })
        this.loadEditInfo(this.model)
      }
      this.handleGoodsSearch(undefined)
    })
  },
  methods: {
    loadEditInfo (data) {
      const { form } = this
      const formData = pick(data, fields)
      console.log('formData', formData)
      form.setFieldsValue(formData)
    },
    handleGoodsChange (value) {
      this.selectGoods = undefined
      if (value === undefined) {
        this.handleGoodsSearch(undefined)
      } else {
        this.selectGoods = this.goodsList.find(item => value === item.barcode)
        // this.handleGoodsInUnion()
      }
    },
    disabledStartDate (startValue) {
      const endValue = this.form.getFieldValue('end_date')
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate (endValue) {
      const startValue = this.form.getFieldValue('start_date')
      console.log(startValue.valueOf())
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    handleStartOpenChange (open) {
      console.log(open)
      this.startOpen = open
    },
    handleEndOpenChange (open) {
      this.endOpen = open
    },
    handleGoodsSearch (value) {
      this.goodsList = []
      this.goods_fetching = true
      listGoods({ name: value }).then(({ data }) => {
        const result = data.entries || []
        this.goodsList = result
      }).finally(() => {
        this.goods_fetching = false
      })
    },
    goodsSearch: debounce(function (val) {
      this.handleGoodsSearch(val)
    }, 800)
  }
}
</script>
<style>
</style>
